import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {},
    mutations: {
        get_letters_success(state) {
            console.log("get_letters_success")
        },
        get_letters_error(state) {
            console.log("get_letters_error")
        },
        delete_letter_success(state) {
            console.log("delete_letter_success")
        },
        delete_letter_error(state) {
            console.log("delete_letter_error")
        },
        edit_letter_success(state) {
            console.log("edit_letter_success")
        },
        edit_letter_error(state) {
            console.log("edit_letter_error")
        },
        send_letter_error(state) {
            console.log("send_letter_error")
        },
        send_letter_success(state) {
            console.log("send_letter_success")
        },

        get_html_success(state) {
            console.log("get_html_success")
        },

        get_html_error(state) {
            console.log("get_html_error")
        },
        set_star_success() {
            console.log('set_star_success')
        },
        set_star_error() {
            console.log('set_star_error')
        },
        set_flagged_success() {
            console.log('set_flagged_success')
        },
        set_flagged_error() {
            console.log('set_flagged_error')
        },
        set_paid_success() {
            console.log('set_paid_success')
        },
        set_paid_error() {
            console.log('set_flagged_error')
        },
        delete_letter_mid_success() {
            console.log('delete_letter_mid_success')
        },
        delete_letter_mid_error() {
            console.log('delete_letter_mid_error')
        },
        search_letter_mid_success() {
            console.log('search_letter_mid_success')
        },
        search_letter_mid_error() {
            console.log('search_letter_mid_error')
        }

    },
    actions: {
        editLetter({commit, state, getters, rootGetters}, letter) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-letter/edit-letter`, method: 'POST', data: letter})
                    .then(resp => {
                        let data = resp.data;
                        commit('edit_letter_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('edit_letter_error')
                        reject(err.response.data)
                    })
            })
        },

        setStar({commit, state, getters, rootGetters}, data) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-letter/set-star`, method: 'POST', data: data})
                    .then(resp => {
                        let data = resp.data;
                        commit('set_star_success');
                        resolve(data)
                    })
                    .catch(err => {
                        commit('set_star_error')
                        reject(err.response.data)
                    })
            })
        },
        setFlagged({commit, state, getters, rootGetters}, data) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-letter/set-flagged`, method: 'POST', data: data})
                    .then(resp => {
                        let data = resp.data;
                        commit('set_flagged_success');
                        resolve(data)
                    })
                    .catch(err => {
                        commit('set_flagged_error')
                        reject(err.response.data)
                    })
            })
        },
        setPaid({commit, state, getters, rootGetters}, data) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-letter/set-paid`, method: 'POST', data: data})
                    .then(resp => {
                        let data = resp.data;
                        commit('set_paid_success');
                        resolve(data)
                    })
                    .catch(err => {
                        commit('set_paid_error')
                        reject(err.response.data)
                    })
            })
        },


        deleteMid({commit, state, getters, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-letter/delete-letter-mid`,
                    method: 'POST',
                    data: {id: params.id, mid: params.mid}
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('delete_letter_mid_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('delete_letter_mid_error')
                        reject(err.response.data)
                    })
            })
        },

        searchMid({commit, state, getters, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-letter/search-letter-mid`,
                    method: 'POST',
                    data: {id: params.id}
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('search_letter_mid_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('search_letter_mid_error')
                        reject(err.response.data)
                    })
            })
        },

        deleteLetter({commit, state, getters, rootGetters}, letter) {
            return new Promise((resolve, reject) => {
                axios({url: `${process.env.VUE_APP_API_URL}/api-letter/delete-letter`, method: 'POST', data: letter})
                    .then(resp => {
                        let data = resp.data;
                        commit('delete_letter_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('delete_letter_error')
                        reject(err.response.data)
                    })
            })
        },
        deleteLetterAll({commit, state, getters, rootGetters}, letter) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-letter/delete-letter?all=true`,
                    method: 'POST',
                    data: letter
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('delete_letter_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('delete_letter_error')
                        reject(err.response.data)
                    })
            })
        },
        getHtml({commit, state, getters, rootGetters}, params) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-letter/get-html?id=${params.id}&record=${params.record}`,
                    method: 'GET'
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('get_html_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_html_error')
                        reject(err.response.data)
                    })
            })
        },
        getLetters({commit, state, getters, rootGetters}, params) {


            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-letter/get-letters?edited=${params.edited}`,
                    method: 'GET'
                })
                    .then(resp => {
                        let data = resp.data;

                        commit('get_letters_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('get_letters_error')
                        reject(err.response.data)
                    })
            })
        },
        sendLetter({commit, state, getters, rootGetters}, item) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-letter/send-letter`,
                    method: 'POST',
                    data: item
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('send_letter_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('send_letter_error')
                        reject(err.response.data)
                    })
            })
        },
        sendLetterToInbox({commit, state, getters, rootGetters}, item) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-letter/send-letter`,
                    method: 'POST',
                    data: item
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('send_letter_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('send_letter_error')
                        reject(err.response.data)
                    })
            })
        },
        sendLetterToInboxAll({commit, state, getters, rootGetters}, item) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${process.env.VUE_APP_API_URL}/api-letter/send-letter`,
                    method: 'POST',
                    data: item
                })
                    .then(resp => {
                        let data = resp.data;
                        commit('send_letter_success', {data});
                        resolve(data)
                    })
                    .catch(err => {
                        commit('send_letter_error')
                        reject(err.response.data)
                    })
            })
        },
    },
    getters: {}
}
